import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../constant/constants';
import { Utils } from '../../services/utils/utils.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  userName: string;
  @Input() isShowHome: boolean;
  @Input() isShowLogout: boolean;
  @Input() backHomeConfirmMsg: string;

  count: any;
  constructor(
    private userService: UserService,
    private router: Router,
    private utils: Utils,
  ) { }

  ngOnInit() {
    const userInfo = this.userService.getUserLocal();

    this.userName = userInfo && userInfo.name;
  }

  async goHome() {
    if (!this.backHomeConfirmMsg) {
      return this.router.navigate(['/home'], { replaceUrl: true });
    }

    const ok = await this.utils.showAlertConfirm(this.backHomeConfirmMsg);

    if (ok) {
      return this.router.navigate(['/home'], { replaceUrl: true });
    }
  }

  async logout() {
    const ok = await this.utils.showAlertConfirm(CONSTANTS.MESSAGES.LOGOUT_CONFIRMATION);
    if (ok) {
      this.userService.logOut();
      this.router.navigate(['/home/login'], { replaceUrl: true });
    }
  }
}
