import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Base64Upload } from '../../models/base64-upload';
import { UploadFileService } from '../../services/upload-file/upload-file.service';
import { Utils } from '../../services/utils/utils.service';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { CONSTANTS } from '../../constant/constants';
import { GoCarPopover } from '../../component/popover/gocar-popover';
import { ModalUploadPhotoComponent } from '../modal-upload-photo/modal-upload-photo.component';
import * as htmlToImage from 'html-to-image';
import { LoadingComponent } from '../../component/loading/loading';

@Component({
  selector: 'app-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss'],
})
export class CheckListComponent implements OnInit {
  @Input() listCheckList: Array<any>;
  @Input() isViewOnly: boolean;
  @Output() onChangeCheckList: EventEmitter<any> = new EventEmitter();
  @Output() onSubmitCheckList: EventEmitter<any> = new EventEmitter();
  @Output() onSubmitAndCompleteJob: EventEmitter<any> = new EventEmitter();
  OTHER = CONSTANTS.OTHER;
  CONSTANTS = CONSTANTS;
  imageCarMarked: any;

  exteriorItemSelected: any
  curentCarWidth = 800
  curentCarHeight = 1217
  newCarWidth = 0
  newCarHeight = 0
  ratio = 1
  maxWidth = 400;
  constructor(
    private utils: Utils,
    private domSanitizer: DomSanitizer,
    private gocarPopover: GoCarPopover,
    private loadingComponent: LoadingComponent,
    private uploadFileService: UploadFileService,
  ) { }

  ngOnInit() {
    this.scaleCarDiagram(-100);
  }

  async addPhoto(checkList, checkListItem) {
    // if (checkList.jobTypeId == CONSTANTS.JOB_TYPE_ID.INSPECTION) {
    //   const imgResult = await this.gocarPopover.show({
    //     component: ModalUploadPhotoComponent,
    //     cssClass: 'popover-large',
    //     backdropDismiss: false,
    //     translucent: true
    //   })

    //   if (imgResult) {
    //     if (!checkListItem.images) {
    //       checkListItem.images = []
    //     }
    //     imgResult.checkListItemId = checkListItem.id
    //     checkListItem.images = checkListItem.images.concat(imgResult)
    //     this.emitChanged()
    //   }
    // } else {
    //   document.getElementById(`image-job-${checkListItem.id}`).click();
    // }
    document.getElementById(`image-job-${checkListItem.id}`).click();
  }

  async imageConvertFromHTML() {
    this.maxWidth = 400
    this.scaleCarDiagram(0);
    this.scaleCarDiagram(-100);
    await this.utils.delay(100);
    const node = document.getElementById('image-car-marked')
    if (node) {
      try {
        this.loadingComponent.showLoading();
        const options = {
          type: 'image/png',
        };
        // remove this css to fix convert car diagram to img on desktop site
        node.style.margin = 'unset';

        // if safari: convert 3 times to fix issue missing background-image
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
          await htmlToImage.toPng(node, options)
          await htmlToImage.toPng(node, options)
          await htmlToImage.toPng(node, options)
        }

        const dataUrl = await htmlToImage.toPng(node, options)
        this.imageCarMarked = {
          "createdAt": new Date(),
          "file": dataUrl,
          "progress": 0
        }

        node.style.margin = '0 auto';
      } catch (error) {
        console.error('oops, something went wrong!', error);
      } finally {
        this.loadingComponent.hideLoading();
      }
    } else {
      this.imageCarMarked = null
    }
  }

  async onSubmit(checkListId) {
    if (this.validate(checkListId)) {
      if (this.isReportExterior(checkListId)) {
        await this.imageConvertFromHTML()
      }

      this.onSubmitCheckList.emit({ checkListId, imageCarMarked: this.imageCarMarked })
    }
  }

  onSubmitAndComplete(checkListId) {
    if (this.validate(checkListId)) {
      this.onSubmitAndCompleteJob.emit({ checkListId })
    }
  }

  validate(checkListId) {
    let valid = true
    const checkList = this.listCheckList.find(e => e.id == checkListId)
    checkList.checkListItems.forEach(checkListItem => {
      if (checkListItem.type == CONSTANTS.CHECK_LIST_ITEM_TYPE.UPLOAD_PHOTO && (!checkListItem.images || checkListItem.images.length == 0)) {
        this.utils.showToastWarning("Please select image!")
        return valid = false
      }
    })
    return valid
  }

  clear(checkListId) {
    const checkList = this.listCheckList.find(e => e.id == checkListId)
    checkList.checkListItems.forEach(checkListItem => {
      if (checkListItem.images) {
        checkListItem.images = []
      }
      if (checkListItem.exteriorItems) {
        checkListItem.exteriorItems = []
      }
      this.exteriorItemSelected = null
      checkListItem.isCheck = false
    })
  }

  async attachImage(event, checkListItem) {
    console.log("checkListItem =====> ", checkListItem)
    const files = event.target.files;
    const tmpFiles = [];
    let isValidType = true;
    if (files && files.length > 0) {

      for (const file of files) {
        if (file && (file.type == 'image/jpeg' || file.type == 'image/png')) {
          // let fileUpload = new Upload(file);
          const thumb = await this.utils.fileToBase64(file);

          const base64: any = await this.uploadFileService.compressImage(
            thumb,
            environment.resizeImage.width,
            environment.resizeImage.height
          );

          const imageFile = {
            fileUpload: new Base64Upload(base64),
            thumb: this.makeTrustedImage(thumb),
            checkListItemId: checkListItem.id
          };

          tmpFiles.push(imageFile);
          continue;
        }

        isValidType = false;
      }

      if (!isValidType) {
        this.utils.showToastError('Please select image');
        return;
      }

      if (!checkListItem.images) {
        checkListItem.images = []
      }
      checkListItem.images = checkListItem.images.concat(tmpFiles)

      this.emitChanged()

      // clear input
      const inp = document.getElementById(`image-job-${checkListItem.id}`);
      inp['value'] = null;
    }
  }

  removeImage(images, i) {
    _.remove(images, (item, index) => {
      return index == i;
    });
    this.emitChanged()
  }

  makeTrustedImage(item) {
    const imageString = JSON.stringify(item).replace(/\\n/g, '');
    const style = 'url(' + imageString + ')';
    return this.domSanitizer.bypassSecurityTrustStyle(style);
  }

  allowSubmit(checkList) {
    checkList.checkListItem.forEach(item => {
      return item && item.images && item.images.length > 0
    })
    return false
  }

  ionChangeCheckListItem() {
    if (this.isViewOnly) {
      return
    }
    this.emitChanged()
  }

  emitChanged() {
    this.onChangeCheckList.emit(this.listCheckList)
  }

  addCheckListitem(checkList) {
    checkList.checkListItems = checkList.checkListItems || [];
    checkList.checkListItems.push({ name: '', id: (new Date()).getTime(), checkListId: CONSTANTS.OTHER })
  }

  onSelectExteriorItem(item) {
    if (this.isViewOnly) {
      return
    }
    this.exteriorItemSelected = this.exteriorItemSelected && this.exteriorItemSelected.id == item.id ? null : item
  }

  zoom(type) {
    if (type == 'out') {
      this.scaleCarDiagram(100)
    } else if (type == 'in') {
      this.scaleCarDiagram(-100)
    }
  }

  scaleCarDiagram(width) {
    // Check if the current width is larger than the max
    if ((this.maxWidth + width) <= 1500 && (this.maxWidth + width) >= 300) {
      this.maxWidth = this.maxWidth + width
      this.ratio = this.maxWidth / this.curentCarWidth;   // get ratio for scaling image
      this.newCarWidth = Math.round(this.curentCarWidth * this.ratio);    // Reset width to match scaled image
      this.newCarHeight = Math.round(this.curentCarHeight * this.ratio);    // Reset height to match scaled image
    }
    console.log(this.ratio)
    this.scaleReportExterior()
  }

  scaleReportExterior() {
    // 0.375 is default for old data
    this.listCheckList.map(checklist => {
      if (checklist.jobExteriors && checklist.jobExteriors.length) {
        checklist.jobExteriors.map(item => {
          item.coordX2 = Math.round(item.coordX * (this.ratio / (item.ratio || 0.375)))
          item.coordY2 = Math.round(item.coordY * (this.ratio / (item.ratio || 0.375)))
          return item
        })
      }
    })

    // this.exteriorItemsNew.map(item => {
    //   item.coordX2 = Math.round(item.coordX * (this.ratio / item.ratio))
    //   item.coordY2 = Math.round(item.coordY * (this.ratio / item.ratio))
    //   return item
    // })
  }

  addExteriorItem(event: MouseEvent, checkListItem) {
    if (this.isViewOnly) {
      return
    }
    if (this.exteriorItemSelected) {
      let coord = {
        checkListItemId: checkListItem.id,
        type: this.exteriorItemSelected.id,
        coordX: event.offsetX - 7,
        coordY: event.offsetY - 7,
        ratio: this.ratio,
        color: this.exteriorItemSelected.color,
        text: this.exteriorItemSelected.text
      }
      if (!checkListItem.exteriorItems) {
        checkListItem.exteriorItems = []
      }
      checkListItem.exteriorItems = checkListItem.exteriorItems.concat(coord)
    }
  }

  removeExteriorItem(event, index, checkListItem) {
    if (this.isViewOnly) {
      return
    }
    event.stopPropagation();
    checkListItem.exteriorItems.splice(index, 1);
  }

  private isReportExterior(checkListId) {
    const checkList = this.listCheckList.find(e => e.id == checkListId)
    return checkList && checkList.checkListItems && checkList.checkListItems.map(e => e.type).includes(CONSTANTS.CHECK_LIST_ITEM_TYPE.REPORT_EXTERIOR);
  }
}
