import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
    public setData(key, data) {
        if (typeof (data) !== 'string') {
            data = JSON.stringify(data);
        }
        localStorage.setItem(key, data);
    }

    public getData(key) {
        const data = localStorage.getItem(key);
        try {
            return data ? JSON.parse(data) : data;
        } catch (error) {
            return data;
        }
    }

    public cleardata(key) {
        localStorage.removeItem(key);
    }
}
