import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';
import { CONSTANTS } from '../../constant/constants';
import { UserService } from '../../user/user.service';
import { FaLocationService } from '../fa-location/fa-location.service';
import * as moment from 'moment';
import { LoadingComponent } from '../../component/loading/loading';
@Injectable()
export class Utils {
  loading: any;
  errorMessage = 'An error occurred, please try again later!';
  constructor(
    private alertCtrl: AlertController,
    private toastController: ToastController,
    private userService: UserService,
    private faLocationService: FaLocationService,
    private loadingComponent: LoadingComponent
  ) { }
  showConstantName(constants, value) {
    if (!constants) {
      return '';
    }
    const arrays = Object.keys(constants).map(key => constants[key]);
    const item = arrays.find(item => item.value === value);

    return item && item.name || '';
  }

  async showAlertError(message) {
    let alert = await this.alertCtrl.create({
      header: CONSTANTS.MESSAGES.ALERT_ERROR_HEADER,
      message: `<p>${message}</p>`,
      cssClass: 'alert-error',
      buttons: ['Ok']
    });
    await alert.present();
  }

  showAlertConfirm(message) {
    return new Promise(async (res, rej) => {
      let alert = await this.alertCtrl.create({
        header: CONSTANTS.MESSAGES.COMFIRM_HEADER,
        message: `<p>${message}</p>`,
        cssClass: '',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          },
          {
            text: 'Ok',
            handler: () => {
              res(true)
            }
          }
        ]
      });
      await alert.present();
    })
  }

  async showToastOnTop(msg) {
    const toast = await this.toastController.create({
      position: 'top',
      message: msg,
      duration: 1500
    });
    toast.present();
  }

  async showToastBottom(msg) {
    const toast = await this.toastController.create({
      position: 'bottom',
      message: msg,
      duration: 1500
    });
    toast.present();
  }

  async showToastSuccess(msg) {
    const toast = await this.toastController.create({
      position: 'top',
      message: msg,
      color: 'success',
      cssClass: 'text-center',
      duration: 1500
    });
    toast.present();
  }

  async showToastError(msg?) {
    const toast = await this.toastController.create({
      position: 'top',
      message: msg || this.errorMessage,
      color: 'danger',
      cssClass: 'text-center',
      duration: 1500
    });
    toast.present();
  }

  handleError(error?) {
    const statusCode = error && error.status;
    const message = statusCode && statusCode === CONSTANTS.STATUS_CODE.INTERNAL_SERVER_ERROR ? this.errorMessage
      : (error && error.message || this.errorMessage);

    return this.showToastError(message);
  }

  fileToBase64(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  showAlertConfirmWithInput(message, name, placeholder, title = '') {
    return new Promise(async (res, rej) => {
      const alert = await this.alertCtrl.create({
        header: CONSTANTS.MESSAGES.COMFIRM_HEADER,
        message: `<p>${message}</p>`,
        cssClass: '',
        inputs: [
          {
            type: 'text',
            name,
            placeholder,
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          },
          {
            text: 'Yes',
            handler: (data) => {
              if (!data[name]) {
                alert.message = `<p class="alert-message">${message}</p><br/>
                <p class="alert-message-error">${title ? title : name} is required</p>`;
                return false;
              }

              res(data);
            }
          }
        ]
      });
      await alert.present();
    });
  }

  logFALocation(action) {
    try {
      if (!action) {
        return;
      }

      const user = this.userService.getUserLocal();
      if (!user) {
        return;
      }

      let self = this;
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const body = {
            lat, lng, action,
            userId: user.id,
            name: user.name,
            email: user.email,
            phoneNumber: user.phoneNumber,
          }
          self.faLocationService.create(body);
        },
        (error) => {
          console.log('getCurrentPosition error', error);
        }
      );
    } catch (error) {
      console.log('logFALocation error', error);
    }
  }


  convertDate(date, format = 'YYYY-MM-DD') {
    if (date) {
      return moment.parseZone(date).format(format)
    }
  }

  async showToastWarning(msg) {
    const toast = await this.toastController.create({
      position: 'top',
      message: msg,
      color: 'warning',
      cssClass: 'text-center',
      duration: 1000 * 10 // 10s
    });
    toast.present();
  }

  navigateGoogleMap(lat, lng) {
    this.loadingComponent.showLoading();
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        try {
          this.loadingComponent.hideLoading();

          let newWin = window.open(`http://maps.google.com/maps?saddr=${position.coords.latitude},${position.coords.longitude}&daddr=${lat},${lng}`, '_blank');

          if (!newWin || newWin.closed || typeof newWin.closed == undefined) {
            alert('Popup Blocker is enable. Please allow popups for this website!');
          }
        } catch (error) {
          console.log('error', error);
          this.loadingComponent.hideLoading();
          return this.showToastError(error.message);
        }
      },
      (error) => {
        this.loadingComponent.hideLoading();
        console.log('error', error);
      }
    );
  }

  navigateWaze(lat, lng) {
    window.open(`https://waze.com/ul?ll=${lat},${lng}&navigate=yes&zoom=17`, '_blank');
  }

  delay(ms: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  checkUniqueLinkDeliveryForm(encodedString) {
    if (!encodedString) {
      return { isUniqueLinkDF: false };
    }
    const decodedText = atob(encodedString);
    const array = decodedText && decodedText.split('|');
    const phoneNumber = array && array[0];
    const code = array && array[1];
    if (!phoneNumber || !code) {
      return { isUniqueLinkDF: false };
    }

    return { isUniqueLinkDF: true, phoneNumber, code };
  }
}
