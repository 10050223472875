import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ROUTES } from '../constant/routes';
import { UserService } from '../user/user.service';
import { Utils } from '../services/utils/utils.service';

@Injectable()
export class AuthLoggedIn implements CanActivate {

  constructor(
    private navCtrl: NavController,
    private userService: UserService,
    private utils: Utils,
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const loggedUser = this.userService.getUserLocal();
    if (state.url.includes('/login')) {
      const result = this.utils.checkUniqueLinkDeliveryForm(route.queryParams && route.queryParams.key);
      if (result.isUniqueLinkDF) {
        this.userService.logOut();
        return true;
      } else {
        if (loggedUser) {
          this.navCtrl.navigateRoot(`${ROUTES.HOME}`);
        }
        return true;
      }
    }

    if (!loggedUser) {
      this.navCtrl.navigateRoot(`${ROUTES.HOME}/${ROUTES.LOGIN}`);
      return false;
    }
    return true;
  }
}
