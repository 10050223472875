import { Component, OnInit } from '@angular/core';
import { environment as config } from 'src/environments/environment';
@Component({
  selector: 'app-version',
  templateUrl: './app-version.component.html',
  styleUrls: ['./app-version.component.scss'],
})
export class AppVersionComponent implements OnInit {

  appVersion = config.appVersion;
  constructor() { }

  ngOnInit() { }

}
