import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable()
export class Nav {
    data: any;
    isCanBack: boolean

    constructor(
        public navCtrl: NavController,
        public router: Router
    ) {
        // ...
    }

    push(url: string, data?) {
        this.isCanBack = true
        this.data = data || null;
        this.navCtrl.navigateForward(url);
    }

    setRoot(url: string, data?) {
        this.isCanBack = false
        this.data = data || null;
        this.router.navigate([url], { replaceUrl: true })
        // this.navCtrl.navigateRoot(url);
    }

    pop(data?) {
        if (data) {
            this.data = data;
        }
        if (!this.isCanBack) {
            this.goHome()
        } else {
            this.navCtrl.back();
        }
    }

    back(data?) {
        if (data) {
            this.data = data;
        }
        this.navCtrl.back();
    }

    popTo(url, data?) {
        if (data) {
            this.data = data;
        }
        this.navCtrl.navigateBack(url)
    }

    goHome(data?) {
        if (data) {
            this.data = data;
        }
        this.router.navigate(['/home'], { replaceUrl: true })
        // this.navCtrl.navigateRoot('/home')
    }

    get(key: string) {
        if (!this.data) {
            return null
        }
        return this.data[key];
    }
}