import { Component, OnInit } from '@angular/core';
import { GoCarPopover } from '../popover/gocar-popover';

@Component({
  selector: 'app-direction-popover',
  templateUrl: './direction-popover.component.html',
  styleUrls: ['./direction-popover.component.scss'],
})
export class DirectionPopoverComponent implements OnInit {

  constructor(
    private gocarPopever: GoCarPopover
  ) { }

  directionAction = {
    waze: 'waze',
    google: 'google'
  };

  ngOnInit() {}

  close() {
    this.gocarPopever.dismiss();
  }

  onSelect(action) {
    this.gocarPopever.dismiss({
      action,
    });
  }
}
