import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ReplaceStringPipe } from './replace-string.pipe';
import { DateTimeFormatPipe } from './date-time-format.pipe';
import { PricingFormatPipe } from './pricing-format.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ReplaceStringPipe,
    DateTimeFormatPipe,
    PricingFormatPipe
  ],
  exports: [
    ReplaceStringPipe,
    DateTimeFormatPipe,
    PricingFormatPipe
  ],
  providers: [
    DecimalPipe,
  ]
})
export class GocarPipeModule {
  static forRoot() {
    return {
      ngModule: GocarPipeModule,
      providers: [],
    };
  }
}
