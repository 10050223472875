export class Base64Upload {
    $key: string;
    file: string;
    name: string;
    url: string;
    progress: number;
    createdAt: Date = new Date();

    constructor(file: string) {
        this.file = file;
        this.progress = 0;
    }
}
