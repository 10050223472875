import { Component, OnInit } from '@angular/core';
import { GoCarPopover } from '../../component/popover/gocar-popover';
import { Utils } from '../../services/utils/utils.service';
import { Base64Upload } from '../../models/base64-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { UploadFileService } from '../../services/upload-file/upload-file.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-upload-photo',
  templateUrl: './modal-upload-photo.component.html',
  styleUrls: ['./modal-upload-photo.component.scss'],
})
export class ModalUploadPhotoComponent implements OnInit {
  image: any = {}

  constructor(
    private gocarPopover: GoCarPopover,
    private utils: Utils,
    private domSanitizer: DomSanitizer,
    private uploadFileService: UploadFileService
  ) {
  }

  ngOnInit() {
  }

  addPhoto() {
    document.getElementById(`image-job`).click();
  }

  async attachImage(event) {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0]
      if (file && (file.type == 'image/jpeg' || file.type == 'image/png')) {
        // let fileUpload = new Upload(file);
        const thumb = await this.utils.fileToBase64(file);

        const base64: any = await this.uploadFileService.compressImage(
          thumb,
          environment.resizeImage.width,
          environment.resizeImage.height
        );

        const imageFile = {
          fileUpload: new Base64Upload(base64),
          thumb: this.makeTrustedImage(thumb),
        };

        this.image = imageFile
      } else {
        this.utils.showToastError('Please select image');
      }
    }
  }

  makeTrustedImage(item) {
    const imageString = JSON.stringify(item).replace(/\\n/g, '');
    const style = 'url(' + imageString + ')';
    return this.domSanitizer.bypassSecurityTrustStyle(style);
  }

  continue() {
    if (!this.image.fileUpload) {
      this.utils.showToastError('Please select image.!')
    } else if (!this.image.caption) {
      this.utils.showToastError('Please add caption.!')
    } else {
      this.gocarPopover.dismiss(this.image);
    }
  }

  close() {
    this.gocarPopover.dismiss();
  }

}
