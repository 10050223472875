export const ROUTES = {
    LOGIN: 'login',
    SUBMIT_OTP: 'submit-otp',
    HOME: 'home',
    JOB: 'job-detail',
    JOB_HISTORY: 'job-history',
    PERFORMANCE: 'performance',
    CAR_CONTROLLER: 'car-controller',
    DELIVERY_FORM: 'delivery-form'
};
