import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';


@Component({
    selector: 'loading',
    templateUrl: './loading.html',
    styleUrls: ['./loading.scss']
})

export class LoadingComponent {

    loading: any;

    constructor(private loadingCtr: LoadingController) {
        // console.log('Hello Loading Component');
    }

    async showLoading(message?: string, duration?: number) {
        if (this.loading) {
            return;
        }
        this.loading = true;
        const loadingCtr = await this.loadingCtr.create({
            spinner: null,
            message: '<div class="cover-spin2"></div>'
        });
        await loadingCtr.present();
        if (!this.loading) {
            this.hideLoading();
        }
        return true;
    }

    async hideLoading() {
        this.loading = false;
        if (this.loadingCtr) {
            await this.loadingCtr.dismiss();
        }
        return true;
    }
}
