import { Directive, HostListener, Input } from '@angular/core';
import { Utils } from '../services/utils/utils.service';

@Directive({
  selector: '[appLogFaLocation]'
})
export class LogFaLocationDirective {
  @Input() action = '';

  constructor(
    private utils: Utils,
  ) { }

  @HostListener('click')
  onClick() {
    this.utils.logFALocation(this.action);
  }
}
