import { Component, OnInit } from '@angular/core';
import { GoCarPopover } from 'src/app/shared/component/popover/gocar-popover';
import { Utils } from 'src/app/shared/services/utils/utils.service';

@Component({
  selector: 'app-modal-job-inspection',
  templateUrl: './modal-job-inspection.component.html',
  styleUrls: ['./modal-job-inspection.component.scss'],
})
export class ModalJobInspectionComponent implements OnInit {
  constructor(
    private gocarPopover: GoCarPopover,
    private utils: Utils
  ) {
    let param = this.gocarPopover.get('carPlateNo');
    if (param) {
      this.carPlateNo = param
    }
  }

  isShowConfirm: boolean = true
  isWaiting = false;
  carPlateNo: any = '';

  ngOnInit() {
  }

  continue() {
    if (!this.carPlateNo) {
      this.utils.showToastError('Please input Car Plate No.!')
    } else {
      this.gocarPopover.dismiss({ carPlateNo: this.carPlateNo });
    }
  }

  close() {
    this.gocarPopover.dismiss();
  }
}
