import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class CarsService extends BaseService {
  url = 'cars';
  constructor(
    public http: Http,
    public router: Router,
    public navCtrl: NavController
  ) {
    super(http, router, navCtrl);
  }

  triggerHonk(id) {
    return this.getData(`${this.url}/${id}/trigger-honk`);
  }

  triggerHazard(id) {
    return this.getData(`${this.url}/${id}/trigger-hazard`);
  }

  lockCar(id, body) {
    return this.postData(`${this.url}/${id}/lock-car`, body);
  }

  unlockCar(id, body) {
    return this.postData(`${this.url}/${id}/unlock-car`, body);
  }
}
