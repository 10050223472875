
import { Component } from "@angular/core";
import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';

@Component({
  selector: 'app-fml-modal',
  templateUrl: './fml-modal.component.html',
  styleUrls: ['./fml-modal.component.scss'],
})
export class FmlModal {
  private modal
  data: any
  constructor(public modalCtrl: ModalController) { }

  public async showModal(component, data?) {
    this.data = data || null;
    return new Promise(async (resolve, reject) => {
      this.modal = await this.modalCtrl.create({
        component: component,
        componentProps: data
      })
      await this.modal.present()
      this.modal.onDidDismiss().then(result => {
        resolve(result.data)
      })
    })
  }

  public async showModalWithOption(option: ModalOptions, data?) {
    this.data = data || null;
    return new Promise(async (resolve, reject) => {
      this.modal = await this.modalCtrl.create(option)
      await this.modal.present()
      this.modal.onDidDismiss().then(result => {
        resolve(result.data)
      })
    })
  }

  public async showModalWithoutDismiss(component, data?) {
    let modal = await this.modalCtrl.create({
      component: component,
      componentProps: data
    });
    return await modal.present()

  }

  public currentModal() {
    return this.modal
  }

  public hideModal(data?) {
    if (this.modalCtrl) {
      this.modalCtrl.dismiss(data);
    }

  }

  get(key: string) {
    if (!this.data) {
      return null
    }
    return this.data[key];
  }
}