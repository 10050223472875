import { Component } from "@angular/core";
import { NavParams, Events } from '@ionic/angular';
import { GoCarPopover } from '../popover/gocar-popover';

@Component({
  selector: 'upload-progress',
  templateUrl: './upload-progress.html',
  styleUrls: ['./upload-progress.scss']
})
export class UploadProgressComponent {
  public progress: any = 0
  public uploaded: number = 0
  constructor(
    private navParams: GoCarPopover,
    public events: Events
  ) {
    let max = this.navParams.get('max');
    console.log('max', max);
    events.subscribe('progressEvent', (data) => {
      this.uploaded += data.progress;
      console.log(`Added: ${data.progress}% .Uploaded: ${this.uploaded}%`)
      this.progress = Math.floor(this.uploaded / max);
    })
  }

}