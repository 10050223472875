import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable()
export class DateTimeService {
    constructor() {
    }

    formatShortMonth(date, hideTime = false) { // ex: 13 Apr 2022 ( 01:00pm )
        const dateFormat = `DD MMM YYYY ${hideTime ? '' : '( hh:mma )'}`;
        return moment(date).format(dateFormat);
    }

    formatDateMonthYear(date, format = 'DD MMM YYYY') {
        if (!date) {
            return '';
        }
        return moment(date).format(format);
    }

    formatHourMinute(date, format = 'hh:mma') {
        if (!date) {
            return '';
        }
        return moment(date).format(format);
    }

    formatFullMonth(date) { // ex: 04/02/2022 10:25:27
        return moment(date).format('DD/MM/YYYY hh:mm:ss');
    }
}
