import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { CONSTANTS } from '../../constant/constants';
import { DateTimeService } from '../../services/utils/date_time.service';

@Component({
  selector: 'app-job-info',
  templateUrl: './job-info.component.html',
  styleUrls: ['./job-info.component.scss'],
})
export class JobInfoComponent implements OnInit {

  @Input() job: any;
  jobStatus = CONSTANTS.JOB_STATUS;
  CONSTANTS = CONSTANTS
  constructor(
    public dateTimeService: DateTimeService,
    private navCtrl: NavController
  ) { }

  ngOnInit() { }

  viewDetail() {
    this.navCtrl.navigateForward(`/home/job-detail/${this.job.id}`);
  }

  getColorStatus() {
    if (this.isExpired()) {
      return 'color-status-expired';
    }

    return '';
  }

  private isExpired() {
    return (this.job.status === CONSTANTS.JOB_STATUS.PENDING || this.job.status === CONSTANTS.JOB_STATUS.IN_PROGRESS)
      && this.job.dueDate && new Date(this.job.dueDate) <= new Date();
  }
}
