export const environment = {
  production: false,
  appVersion: require('../../package.json').version,

  API_URL: 'https://fml-api.gocardev.com/fml-api',
  // API_URL: 'http://localhost:8080/fml-api',
  SECRET_KEY_GOCAR_FML: 'gocar-fml',
  SECRET_VALUE_GOCAR_FML: '8HFsEl6TpTtDwy1P3vCWHLgqpXI6Z1wP',

  GOCAR_HELPER_API_URL: 'https://staging2.gocar.my/gocar-helper-api',
  GOCAR_HELPER_SECRET_KEY: 'gocar_helper_key',
  GOCAR_HELPER_SECRET_VALUE: '2XsE3ydpFukcObeEp6QV8IKJZInhDdmW',

  firebaseConfig: {
    apiKey: 'AIzaSyDEMWGrK31Ya8YWCHCB_MAB9dnXTzAavh4',
    authDomain: 'fir-app-2c3a2.firebaseapp.com',
    databaseURL: 'https://fir-app-2c3a2.firebaseio.com',
    projectId: 'fir-app-2c3a2',
    storageBucket: 'fir-app-2c3a2.appspot.com',
    messagingSenderId: '1099234430996'
  },

  siteKeyCaptcha: '6LfIwawUAAAAAFKkkeXdwYjRAOZFimjnoaFzHA9a',
  resizeImage: {
    width: 800,
    height: 800
  }
};
