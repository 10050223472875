import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AppHeaderComponent } from './component/app-header/app-header.component';
import { DateTimeService } from './services/utils/date_time.service';
import { LocalStorageService } from './services/storage/localstorage.service';
import { UserService } from './user/user.service';
import { UploadFileService } from './services/upload-file/upload-file.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthLoggedIn } from './auth/auth-logged-in';
import { AppVersionComponent } from './component/app-version/app-version.component';
import { LoadingComponent } from './component/loading/loading';
import { UploadProgressComponent } from './component/upload-progress/upload-progress';
import { GoCarPopover } from './component/popover/gocar-popover';
import { HttpModule } from '@angular/http';
import { GocarPipeModule } from './pipe/gocar-pipe.module';
import { JobInfoComponent } from './component/job-info/job-info.component';
import { DirectionPopoverComponent } from './component/direction-popover/direction-popover.component';
import { LogFaLocationDirective } from './directives/log-fa-location.directive';
import { CustomPChartComponent } from './components/custom-p-chart/custom-p-chart.component';
import { Nav } from './services/nav/nav.service';
import { CheckListComponent } from './components/check-list/check-list.component';
import { FmlModal } from './component/fml-modal/fml-modal.component';
import { ConfirmCarLockedComponent } from '../home/confirm-car-locked/confirm-car-locked.component';
import { ModalJobInspectionComponent } from '../home/modal-job-inspection/modal-job-inspection.component';
import { ModalUploadPhotoComponent } from './components/modal-upload-photo/modal-upload-photo.component';
import { ModalInputCodeComponent } from '../home/modal-input-code/modal-input-code.component';

const Components = [
    AppHeaderComponent,
    AppVersionComponent,
    UploadProgressComponent,
    GoCarPopover,
    LoadingComponent,
    JobInfoComponent,
    DirectionPopoverComponent,
    CustomPChartComponent,
    CheckListComponent,
    FmlModal,
    ConfirmCarLockedComponent,
    ModalJobInspectionComponent,
    ModalUploadPhotoComponent,
    ModalInputCodeComponent
];
@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        RouterModule,
        FormsModule,
        HttpModule,
        ReactiveFormsModule,
        GocarPipeModule
    ],
    providers: [
        DateTimeService,
        LocalStorageService,
        AuthLoggedIn,
        UserService,
        UploadFileService,
        AngularFireStorage,
        Nav,
        FmlModal
    ],
    declarations: [
        ...Components,
        LogFaLocationDirective
    ],
    entryComponents: [
        ...Components
    ],
    exports: [
        ...Components,
        LogFaLocationDirective
    ],
})
export class SharedModule { }
