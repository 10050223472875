import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs';
import { environment as config } from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class FaLocationService {
  constructor(
    private http: Http,
  ) { }

  create(body) {
    const headers = new Headers({
      [config.GOCAR_HELPER_SECRET_KEY]: config.GOCAR_HELPER_SECRET_VALUE,
      'Content-Type': 'application/json'
    });
    const options = new RequestOptions({ headers });
    return this.http.post(`${config.GOCAR_HELPER_API_URL}/bigquery/fa-location`, body, options)
      .map((res: any) => res.json())
      .catch((error) => {
        return Observable.throw(error.json())
      })
      .toPromise();
  }
}
