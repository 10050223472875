import { Component, OnInit } from '@angular/core';
import { GoCarPopover } from 'src/app/shared/component/popover/gocar-popover';
import { Utils } from 'src/app/shared/services/utils/utils.service';

@Component({
  selector: 'app-modal-input-code',
  templateUrl: './modal-input-code.component.html',
  styleUrls: ['./modal-input-code.component.scss'],
})
export class ModalInputCodeComponent implements OnInit {
  constructor(
    private gocarPopover: GoCarPopover,
    private utils: Utils
  ) {
  }

  isShowConfirm: boolean = true
  code: any = '';

  ngOnInit() {
  }

  continue() {
    if (!this.code) {
      this.utils.showToastError('Please input code.!')
    } else {
      this.gocarPopover.dismiss({ code: this.code });
    }
  }

  close() {
    this.gocarPopover.dismiss();
  }
}
