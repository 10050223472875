import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'replaceString'
})
export class ReplaceStringPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) { }

  transform(html?: string): string {
    html = html;
    if (html) {
      html = html.replace('â€¦', "...");
      html = html.replace('â€œ', "“");
      html = html.replace('â€“', "–");
      html = html.replace('â€™', "’");
      html = html.replace('â€', "”");
      return html;
    } else {
      return null;
    }
  }
}
