import { Component, OnInit } from '@angular/core';
import { FmlModal } from 'src/app/shared/component/fml-modal/fml-modal.component';
import { LoadingComponent } from 'src/app/shared/component/loading/loading';
import { CarsService } from 'src/app/shared/services/cars/cars.service';
import { Utils } from 'src/app/shared/services/utils/utils.service';

@Component({
  selector: 'app-confirm-car-locked',
  templateUrl: './confirm-car-locked.component.html',
  styleUrls: ['./confirm-car-locked.component.scss'],
})
export class ConfirmCarLockedComponent implements OnInit {

  carId: any;
  jobId: any;
  constructor(
    private fmlModal: FmlModal,
    private carService: CarsService,
    private loadingComponent: LoadingComponent,
    private utils: Utils
  ) {
  }

  isShowConfirm: boolean = true
  isWaiting = false;

  ngOnInit() {
    this.carId = this.fmlModal.get('carId')
    this.jobId = this.fmlModal.get('jobId')
  }

  yes() {
    this.fmlModal.hideModal(true);
  }

  close() {
    this.fmlModal.hideModal(false);
  }

  async lockCar() {
    if (this.isWaiting) {
      return;
    }

    this.loadingComponent.showLoading()
    const position = await this.getCurrentFALocation();
    const paramLockCar = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      jobId: this.jobId
    }
    this.carService.lockCar(this.carId, paramLockCar).subscribe(val => {
      console.log(val)
      this.loadingComponent.hideLoading()
      this.isShowConfirm = true
    }, err => {
      console.log(err)
      this.utils.showToastWarning(`Unable to lock car`);
      this.isWaiting = true;
      setTimeout(() => {
        this.isWaiting = false
      }, 1000 * 30);
      this.loadingComponent.hideLoading()
    })
  }

  async getCurrentFALocation(): Promise<any> {
    return new Promise((relsove, reject) => {
      navigator.geolocation.getCurrentPosition(relsove, reject);
    });
  }
}
