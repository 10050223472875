export class CONSTANTS {
  public static DECIMAL_REGEX = /^[0-9]\d*(\.\d+)?$/
  public static OPTION = {
    YES: 1,
    NO: 0
  };
  public static STATUS_CODE = {
    OK: 200,
    UNAUTHORIZED: 401,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
  };
  public static LANG_CODE = {
    MALAYSIA: 'ms',
    ENGLISH: 'en',
  };

  public static MESSAGES = {
    COMFIRM_HEADER: 'Confirmation',
    COMFIRM_MAKE_ORDER: 'Place Order',
    COMFIRM_CANCEL_ORDER: 'Cancel Order',
    CONFIRM_SUBMIT: 'Are you sure you want to submit?',
    CONFIRM_SUBMIT_PHOTO: 'Are you sure you want to submit photo?',
    ALERT_ERROR_HEADER: 'Error',
    LOGOUT_CONFIRMATION: 'Are you sure want to logout?',
    CANCEL_JOB_CONFIRM: 'Are you sure you want to cancel this job?',
    COMPLETE_JOB_CONFIRM: 'Are you sure you want to complete this job?',
    REQUEST_JOB_CONFIRM: 'Are you sure you want to request job?',
    GENERATE_RESERVATION_CONFIRM: 'Are you sure you want to Generate Reservation in GoCar App?',
    COMFIRM_BACKHOME_PAGE: 'Are you sure to exit? The photos will be not saved!',
    REMINDER_UPLOAD_PHOTO: 'Please remember to upload photos via WhatsApp!',
    ASSIGN_JOB_CONFIRM: 'Are you sure you want to pick this job?',
  };

  public static CHART_COLORS = {
    COMPLETE_JOB: 'rgba(69, 194, 192, 1)',
    INCOMPLETE_JOB: 'rgba(232, 232, 232, 1)',
  };

  public static JOB_STATUS = {
    PENDING: 'pending',
    IN_PROGRESS: 'in_progress',
    CANCELLED: 'cancelled',
    COMPLETED: 'completed',
  };

  public static DATE_RANGE = {
    MONTHLY: 1,
    WEEKLY: 2,
    DATE_RANGE: 3
  }

  public static JOB_TYPE_ID = {
    CLEANING: 1,
    DEVELOPMENT: 2,
    INSPECTION: 3,
    CAR_SWAP: 4,
    PRE_ASSESSMENT: 5,
    DELIVERY_FORM: 6
  }

  public static OTHER = 'Other_Check_List';

  public static JOB_TYPE_TEXT = {
    1: "CLEANING",
    2: "DEPLOYMENT",
    3: 'INSPECTION',
    4: "CAR SWAP",
    5: 'PRE ASSESSMENT',
    6: 'DELIVERY FORM'
  };

  public static CHECK_LIST_ITEM_TYPE = {
    UPLOAD_PHOTO: 1,
    WITHOUT_UPLOAD_PHOTO: 2,
    REPORT_EXTERIOR: 3,
    TEXT_INPUT: 4
  }
}
